import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getSearchVars, helpers, helperVars, sendRequest } from "../../main";
import Button from "../../reusable/Button";
import MainPage from "../../reusable/MainPage";
import TextMask from "../../reusable/TextMask";
import Privacy from "../special/Privacy";
import Terms from "../special/Terms";

const Enter = (props) => {

    const location = useLocation();
    const prevLocation = helpers.usePrevious(location);

    const [creating, setCreating] = useState(1);
    const [userInfo, setUserInfo] = useState({
        email: "",
        password: "",
        username: "",
    });

    const [errorList, setErrorList] = useState({
        email: [],
        password: [],
        username: []
    });

    // const [validData, setValidData] = useState(false);

    const checkUsername = useRef();
    const checkEmail = useRef();
    const queryVars = useRef({});
    const [showTerms, setShowTerms] = useState(false);
    const [checked, setChecked] = useState(false);
    
    const [consent, setConsent] = useState(false);

    const [serverError, setServerError] = useState("");
    
    const [showPriv, setShowPriv] = useState(false);

    const random = useRef(["join us", "join the family", "we need you", "we've been waiting", "welcome", "join the movement", "you have regimenvision"][Math.floor(Math.random() * 7)]);


    function switchMode(n) {
        setCreating(n);
    }

    function handleVerification(newErrorList, newUserInfo) {
        if (JSON.stringify(errorList) !== JSON.stringify(newErrorList)) {
            
            setErrorList(newErrorList)
            
        }
        setChecked(true);
        
    }

    function handleInput(e) {

        
        setChecked(false);
        let { name, value } = e.target;
        value = value.toLowerCase().trim();
        var tempUserInfo = {
            name, value
        }

        var newErrorList = {...errorList};
        var newError = [];
        switch (name) {
            case "username":
                if (!helpers.validUsername(value)) {
                    return
                }
                if (!value || value.length <= 4) {
                    newError.push("Username must be above 4 characters");
                    
                    newErrorList[name] = newError;
                    handleVerification(newErrorList, tempUserInfo);
                } else {
                    clearTimeout(checkUsername.current);
                    checkUsername.current = window.setTimeout(() => {
                        sendRequest("simple/user", {name: value}, false).then((data) => {
                            if (data.data) {
                                newError.push("Username is taken");
                            }

                            newErrorList[name] = newError;
                            handleVerification(newErrorList, tempUserInfo);
                        })
                        .catch(() => {
                        });
                    }, 200);
                }
                
                
                break;
            case "password":
                if (!value.length) {
                    newError.push("Password can't be blank.");
                }
                break;
            case "email":
                if (!value.length) {
                    newError.push("Email can't be blank.");
                    newErrorList[name] = newError;    
                    handleVerification(newErrorList, tempUserInfo);
                } else if (!helpers.validEmail(value)) {
                    newError.push("Email isn't valid.");
                    newErrorList[name] = newError;    
                    handleVerification(newErrorList, tempUserInfo);
                } else {
                    clearTimeout(checkEmail.current);
                    checkEmail.current = window.setTimeout(() => {
                        sendRequest("simple/user", {name: value}, false).then((data) => {
                            if (data.data) {
                                newError.push("Please use another email.");
                            }

                            newErrorList[name] = newError;
                            handleVerification(newErrorList, tempUserInfo);
                        })
                        .catch(() => {
                        });
                    }, 200);
                }

                break;
            default:
                break;
        }

        if (name !== "username" && name !== "email") {
            newErrorList[name] = newError;
            handleVerification(newErrorList, tempUserInfo);
        }

        
        setUserInfo(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    useEffect(() => {
        setServerError("");
    }, [creating]);

    useEffect(() => {
        if (!!location.search && location.search !== prevLocation.search) {
            queryVars.current = getSearchVars(location.search);
            if (queryVars.current.ref && helpers.validUsername(queryVars.current.ref)) {
                sendRequest("simple/user", {name: queryVars.current.ref}, false).then((data) => {
                    if (data.data) {
                        localStorage.setItem("refUser", queryVars.current.ref);
                    }
                })
                .catch(() => {
                });
            }
        }
    }, [location.search, prevLocation.search])


    function createAccount() {
        
        sendRequest("api/createAccount", {
            name: userInfo.username,
            email: userInfo.email,
            password: userInfo.password
        }).then((response) => {
            window.location.pathname = "/welcome";
        }).catch((response) => {
            if (response.head) {
                setServerError(response.data.info);
            } else {
                setServerError("An error occured on our end. Please try again later.")
            }
        })
    }

    function logIn() {
        
        sendRequest("api/login", {
            name: userInfo.username,
            password: userInfo.password
        }).then((response) => {
            window.location.pathname = "/";
        }).catch((response) => {
            if (response.head) {
                setServerError(response.data.info);
            } else {
                setServerError("An error occured on our end. Please try again later.")
            }
        })
    }

    var logInOkay = (
        userInfo.username.length > 0 && 
        userInfo.password.length > 0 &&
        checked
    );
    var signUpOkay = (
        Object.values(errorList).every(x => x.length === 0) && 
        Object.values(userInfo).every(x => x.length > 0) &&
        checked
    );
    return (
        <MainPage 
            title={random.current}
            page={"enter"}
            active={true}
        >
            {creating === 3 ? 
                <div className={`switch signingUp`}>
                    
                    <div className={`item`}>
                        <span>
                            Making you an account
                        </span>
                    </div>
                </div> :
                <div className={`switch ${creating === 3 && "signingUp"}`} onClick={() => switchMode(creating === 1 ? 2 : 1)}>
                
                    <div className={`item ${creating === 1 ? "active" : ""}`} >
                        <TextMask halfMast={true} dull={creating !== 1} bg={true}>
                            Sign Up
                        </TextMask>
                    </div>

                    <div className={`item ${creating === 2 ? "active" : ""}`}>
                        <TextMask halfMast={true} dull={creating !== 2} bg={true}>
                            Log In
                        </TextMask>
                    </div>
                </div>
            }
            
            
            <div className={"parent"}>

                <div className={`central mode${creating}`}>

                    <div className={`object ${creating === 3 ? "active" : ""} zone3`}>
                        

                        <div>
                            You're about to make an account with the username <b>@{userInfo.username}</b>, and the email <b>{userInfo.email}</b>.
                        </div>
                        <p>
                            Please check this information is correct, your username is permanent, but your email can be changed.
                        </p>
                        <p>
                            <input type={"checkbox"} name={"consent"} id={"consent"} readOnly={true} checked={consent} onClick={() => setConsent(!consent)} /> <label htmlFor={"consent"}>I've read and agree with the </label><span className={"fakeLink"} onClick={() => setShowTerms(!showTerms)}>terms and conditions</span> and also the <span className={"fakeLink"} onClick={() => setShowPriv(!showPriv)}>privacy policy</span>.
                            
                        </p>
                        {showTerms && (
                            <div className={"terms"}>
                                <Terms/>
                            </div>
                        )}
                        {showPriv && (
                            <div className={"terms"}>
                                <Privacy/>
                            </div>
                        )}
                        {!!serverError && (
                            <p>The server returned an error, <b>{serverError}</b> This may be an indication of problems on our end, or the data you've provided is no longer sufficient.</p>
                        )}
                        <div className={"continue"}>
                            <div>
                                <Button onClick={() => switchMode(1)} >
                                    Go Back
                                </Button>
                            </div>
                            <div>
                                <Button onClick={createAccount} disabled={!consent}>
                                    Let's go
                                </Button>
                            </div>
                        </div>
                        
                        
                    </div>

                    <div className={`object ${creating === 1 ? "active" : ""}`}>
                        Welcome to <span className={"logo"}>regimensocial</span>
                        <ul className={"userInfo noListStyle"}>
                            <li className={"listHead"}><b>Username</b></li>
                            <li>
                                <input 
                                    type={"text"} 
                                    className={"username"} 
                                    placeholder={"enter your desired username"} 
                                    value={userInfo.username} 
                                    name={"username"} 
                                    onChange={handleInput}
                                    pattern={helperVars.validUsernameRegex}
                                    disabled={creating !== 1}
                                />
                            </li>
                            <li className={"listHead"}><b>Password</b></li>
                            <li>
                                <input type={"password"} 
                                    className={"password"} 
                                    placeholder={"and a secure password"} 
                                    value={userInfo.password} 
                                    name={"password"} 
                                    onChange={handleInput}
                                    disabled={creating !== 1}
                                />
                            </li>
                            <li className={"listHead"}><b>Email</b></li>
                            <li>
                                <input 
                                    type={"email"} 
                                    className={"email"} 
                                    placeholder={"we also need your email"} 
                                    value={userInfo.email} 
                                    name={"email"} 
                                    onChange={handleInput}
                                    disabled={creating !== 1}
                                />
                            </li>
                            <li className={"listHead"}>Continue</li>
                            <li>
                                <Button disabled={!signUpOkay} onClick={() => setCreating(3)}>
                                    {signUpOkay ? "Join us" : "Check your data first."}
                                </Button>
                            </li>
                        
                        
                        </ul>
                        
                        <div className={`join ${signUpOkay ? "active" : ""}`}>
                            
                        </div>

                        <div className={`errorBox active`}>

                            {Object.entries(errorList).map((i, index) => {
                                var [key, value] = i;
                                if (!value.length) {
                                    return null;
                                }
                                return value.map((error, i) => (
                                    
                                    <div key={`${key}-${i}`}>{error}</div>
                                    
                                ))
                            })}
                        </div>

                    </div>

                    <div  className={`object ${creating === 2 ? "active" : ""}`}>
                        Welcome back.
                        <ul className={"userInfo noListStyle"}>
                            <li className={"listHead"}><b>Username</b></li>
                            <li>
                                <input 
                                    type={"text"} 
                                    className={"username"} 
                                    placeholder={"enter your username"} 
                                    value={userInfo.username} 
                                    name={"username"} 
                                    onChange={handleInput}
                                    pattern={helperVars.validUsernameRegex}
                                    disabled={creating !== 2}
                                />
                            </li>
                            <li className={"listHead"}><b>Password</b></li>
                            <li>
                                <input 
                                    type={"password"} 
                                    className={"password"} 
                                    placeholder={"and your password"} 
                                    value={userInfo.password} 
                                    name={"password"} 
                                    onChange={handleInput}
                                    disabled={creating !== 2}
                                />
                            </li>
                            <li className={"listHead"}>Continue</li>
                            <li>
                                <Button disabled={!logInOkay} onClick={logIn}>
                                    {logInOkay ? "Log In" : "Check your data first."}
                                </Button>
                            </li>
                        </ul>
                        {!!serverError && (
                            <p><b>{serverError}</b></p>
                        )}
                    </div>
                </div>

            </div>
            
        </MainPage> 
    )
};

export default Enter;