import { Component } from "react";
import MainPage from "../../reusable/MainPage";
import SlickContainer from "../../reusable/SlickContainer";
// import Button from "../../reusable/Button";
import Miniselect from "../../reusable/Miniselect";
import logo from "../../assets/logo.svg";
import ascension from "../../assets/regimens/ascension.svg";
import { getHashVars } from "../../main";
import ref from "../../assets/ref.svg";
// import equal from "deep-equal";
class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            stage: 1,
            hashVars: {}
        }
    }
    setStateBasic(val, name) {
        this.setState({
            [name]: val
        })
    }
    componentDidMount() {
        this.setState({
            hashVars: getHashVars(this.props.location.hash)
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.hash !== this.props.location.hash) {
            this.setState({
                hashVars: getHashVars(this.props.location.hash)
            })
        }
    }
    render() { 
        return ( 
            <MainPage title={"welcome"} page={"welcome"} active={this.props.active}>
                <SlickContainer className={"sCmain"} items={[
                    <div>
                        <img src={logo} alt={"regimensocial"}/>
                        
                        <div className={"introText"}>we welcome you.</div>
                        <div className={"introText"}>thank you for joining.</div>
                    </div>,
                    <div className={"ascensionTag"}>
                        <img src={ascension} alt={"ascension"}/>
                        <div className={"introText"}>"Ascension" summer regimen for muscle gain</div>
                        <div className={"introText"}>you begin very soon.</div>
                    </div>, 
                    <div className={"refTag"}>
                        <img src={ref} alt={"referral programme"}/>
                        If a friend referred you, please enter their username now.
                        <input type="text" name={"referral"} {...this.props.enableInput}/>
                    </div>
                ]} 
                    active={this.state.stage}
                    delay={"800ms"}
                    header={
                        <div className={"mScontainer"}>
                            <Miniselect
                                amount={3}
                                active={this.state.stage}
                                setStage={(val) => this.setStateBasic(val, "stage")}
                                width={"40px"}
                                hint={true}
                            />
                        </div>
                    }
                    paddingBottom={"none"}
                />
            </MainPage> 
        );
    }
}
 
export default Welcome;