import { Component } from "react";
import { Link } from "react-router-dom";
import { appInfo } from "../../main";
import MainPage from "../../reusable/MainPage";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <MainPage title={"regimensocial"} active={true}>
                <div>Welcome to <span className={"logo"}>regimensocial</span>.</div>
                <div>I need to fill this page with cool shit when the site is more complete.</div>
                <div>If cert not allowed, <a href={appInfo.address}>here</a></div>
                <Link to={"/enter"}>Login/Sign Up</Link>
            </MainPage> 
        );
    }
}
 
export default Home;