import Home from "./pages/loggedIn/Home";
import Welcome from "./pages/loggedIn/Welcome";
import Profile from "./pages/loggedIn/Profile";
import ViewUser from "./pages/loggedIn/ViewUser";
import ViewGroup from "./pages/loggedIn/ViewGroup";
import SlickContainer from "./reusable/SlickContainer";

import * as Scroll from 'react-scroll';

import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import SpecialBar from "./reusable/SpecialBar";
import Button from "./reusable/Button";
import ViewDay from "./pages/loggedIn/ViewDay";
import Social from "./pages/Social";
import { helpers } from "./main";

var items = {
    MotFound: () => <h1>Can't find it</h1>,
    home: Home,
    welcome: Welcome,
    social: Social,
    profile: Profile,
    u: ViewUser,
    g: ViewGroup,
    r: ViewDay
}

var itemProps = {
    // r: {
        
    // }
}

const Central = (props) => {
    var page = props.match.params.pageAddress ? props.match.params.pageAddress.toLowerCase() : "home";
    var currentPage = Object.keys(items).findIndex(e => e === page) + 1 || 1;

    const [specialBars, setSpecialBars] = useState({
        g: {
            pageName: "",
            buttonHubs: [
                {
                    active: false,
                    buttons: [{
                        text: "",
                        function: null,
                        active: false,
                    },
                    {
                        text: "",
                        function: null,
                        active: false,
                    }]
                }
            ] 
        }
    });

    let location = useLocation();
    let prevLocation = helpers.usePrevious(location);

    console.log(location)
    console.log(prevLocation)

    useEffect(() => {

        if (location.noPull) {
            return
        }

        if (location.pathname !== prevLocation.pathname || !location.forcePull) {
            Scroll.animateScroll.scrollToTop({
                duration: 600,
                delay: 0,
            });
        }

        
    }, [location, prevLocation])

    const handleSpecialBar = (pageName, bar) => {
        setSpecialBars(prevState => ({
            ...prevState,
            [pageName]: bar
        }))
    };
    
    return (
        <div>
            <SlickContainer
                items={Object.entries(items).map((item, index) => {
                    var [key, Page] = item;

                    var moreProps = {};
                    if (itemProps[key]) {
                        moreProps = itemProps[key];
                    }
                    
                    moreProps.active = ((index+1) === currentPage); 
                    moreProps.enableInput = {
                        disabled: true,
                        tabIndex: "-1"
                    }
                    if (moreProps.active) {
                        moreProps.enableInput = {
                            disabled: false,
                            
                        }
                    }
                    moreProps.handleSpecialBar = handleSpecialBar;
                    moreProps.pageName = key;
                    return (
                        <Page {...{...props, ...moreProps}}/>
                    )
                })}
                active={currentPage}
                noMargin={true}
                padding={"0"}
            />
            
            {Object.entries(specialBars).map((item) => {
                var [key, value] = item;
                var active = (key === page);
                return value.buttonHubs.map((bHub, index) => 
                    (
                        <SpecialBar key={index} className={`${value.pageName} ${(bHub.active && active) ? "active" : "inactive"}`}>
                            {bHub.buttons.map((buttonInfo, bIndex) => {
                                return (
                                    <Button key={bIndex} onClick={(key === page) ? buttonInfo.function : null} className={`${bHub.buttons.length === 1 ? "only" : ""} ${buttonInfo.active ? "active" : ""}`}>
                                        {buttonInfo.text}
                                    </Button>
                                )
                            })}
                        </SpecialBar>
                    )  
                ) 
            })}
            
        </div>
    )
}

export default Central;