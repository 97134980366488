import * as appSettings from "./info/settings.json";

import axios from "axios";
import { useEffect, useRef } from "react";
import * as signalR from "@microsoft/signalr";
import { DateTime } from "luxon";
var appInfo = {
    production: true,
    address: "",
    connection: "notInit",
    regimenList: {},
    devCSS: false
}

var env = {};
if (window.location.hostname.includes("192.168") || window.location.hostname.includes("localhost")) {
    appInfo.production = false;
    env = appSettings.development;
    env.host = window.location.hostname.includes("localhost") ? "127.0.0.1" : window.location.hostname;
} else {
    appInfo.production = true;
    env = appSettings.production;
}

appInfo.address = `${env.secure ? "https://" : "http://"}${env.host}:${env.port}`;

// var apiFunctions = {}

var sendRequest = (request, data = {}, post = true) => {
    return new Promise((resolve, reject) => {
        var newRequest = post ? axios.post(`${appInfo.address}/${request}`, data, 
        {withCredentials: true}) : axios.get(`${appInfo.address}/${request}`, {params: data}, 
        {withCredentials: true});

	    newRequest.then(response => {
            resolve(response.data);
	    }).catch(err => {
            if (err.response && err.response.data) {
                reject({
                    head: true, 
                    data: err.response.data
                });
            } else {
                alert("Data no head");
                reject({
                    head: false, 
                    data: err
                });
            }
	    });
    });
}

sendRequest("simple/regimenList").then((r) => {
    appInfo.regimenList = r.data;
});

const helperVars = {
    validUsernameRegex: "[a-zA-Z0-9_]",
    timeFormat: "T dd MMM yyyy",
    dayFormat: "dd MMM yyyy",
    exerciseNames: {
        "unset": "Unset",
        "planks": "Plank", 
        "sidePlanks": "Side Plank", 
        "wallSits": "Wall Sit", 
        "bicycleCrunches": "Bicycle Crunch",  
        "lunges": "Lunge",  
        "squats": "Squat",  
        "legsElevatedPushUps": "Legs-elevated Push-up", 
        "burpees": "Burpee", 
        "regularPushUps": "Push-up",  
        "sitUps": "Sit-up", 
        "noRecord": "No record :/",
        "noActivity": "No activity :)"
    },
    weekDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
}

const helpers = {
    validUsername: (username) => {
        // const regex = /^[a-zA-Z0-9_]*$/g;
        
        const regex = new RegExp(`^${helperVars.validUsernameRegex}*$`, "g");
        return regex.test(username);
    },
    validEmail: (email) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    },
    usePrevious: (value) => {
        const ref = useRef();
        var altVal = undefined;
        if (typeof value === "object") {
            altVal = {};
        }
        useEffect(() => {
          ref.current = value;
        });
        return ref.current || altVal;
    },
    getPermission: (perm, capitalise = false) => {
        var user = "member";
        switch (perm) {
            case 0:
                user = "requested";
                break;
            case 1:
                user = "owner";
                break;
            case 2:
                user = "admin";
                break;
            case 3:
                user = "member";
                break;
            default:
                break;
        }
        if (capitalise) {
            user = user.charAt(0).toUpperCase() + user.slice(1);
        }
        return user;
    }, 
    getProfilePicture: (uuid, count) => {
        return appInfo.address + `/images/profilePicture/?uuid=${uuid}&imageCount=${count}`
    },
    getGroupPicture: (uuid, count) => {
        return appInfo.address + `/images/groupPicture/?uuid=${uuid}&imageCount=${count}`
    },
    readFileDataAsBase64: (e, sendCut = true) => {
        const file = e.target.files[0];
    
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
    
            reader.onload = (event) => {
                resolve(sendCut ? event.target.result.split(",")[1] : event.target.result);
            };
    
            reader.onerror = (err) => {
                reject(err);
            };
    
            reader.readAsDataURL(file);
        });
    },
    getTextDimensions: (text) => {
        var x = document.createElement("SPAN");
        var t = document.createTextNode(text);
        x.appendChild(t);
        x.id = `${Math.floor(Math.random() * 991)}`;
        document.querySelector("#hiddenBox").appendChild(x);
        var result = {
            width: x.offsetWidth + 10,
            height: x.offsetHeight + 10
        }
        x.remove();
        return result;
    },
    getRegimenInfo: (regimen = "2020q4") => {
        var info = appInfo.regimenList.find(x => (x.name === regimen || x.regimenName === regimen));
        if (!info) {
            info = appInfo.regimenList.find(x => (x.name === "2020q4"));
        }
        return info;
    },
    getRegimenDate: (regimen = "2020q4", week = 1, day = 1) => {
        var info = helpers.getRegimenInfo(regimen);
        if (typeof day === "string") {
            day = parseInt(day);
        }
        if (typeof week === "string") {
            week = parseInt(week);
        }
        if (day > 7) {
            week++;
            day = day - 7;
        }

        if (day < 1) {
            week--;
            day = day + 7;
        }
        var startDate = info.startDate;
        var newDate =  DateTime.fromISO(startDate).plus({ days: day - 1, weeks: week - 1 });
        var endDate = DateTime.fromISO(info.endDate);
        if (endDate < newDate) {
            return ({
                name: info.name,
                regimenName: info.regimenName,
                week: info.weekAmount,
                day: 7,
                date: endDate.toFormat(helperVars.dayFormat),
                epoch: newDate.toMillis()
            });
        }
        return ({
            name: info.name,
            regimenName: info.regimenName,
            week,
            day,
            date: newDate.toFormat(helperVars.dayFormat),
            epoch: newDate.toMillis()
        });
    },
    orderObject: (not_sorted) => {
        Object.keys(not_sorted)
        .sort()
        .reduce((acc, key) => ({
            ...acc, [key]: not_sorted[key]
        }), {})
    },
    returnPlural: (amount, properName, exerciseType) => {
        if (exerciseType) {
            properName = helperVars.exerciseNames[exerciseType] || exerciseType;
        }
        if (amount > 1 || amount === 0) {

            if (properName === "is") {
                return "are";
            } else if (properName === "has") {
                return "have";
            }

            if (properName.endsWith("ch")) {
                return properName + "es";
            } else {
                return properName + "s";
            }
        } else {
            return properName;
        }
    },
    checkUserExists: (value) => {
        return new Promise((resolve, reject) => {
            sendRequest("simple/user", {name: value}, false).then((data) => {
                if (data.data) {
                    resolve();
                } else {
                    reject();
                }
            })
            .catch(() => {
                reject();
            })
        });
        
    }
}

// window.onfocus = function(){
//     if (window.innerHeight <= 600) {
//         syncGradient();
//     }
// }

// const handleResize = () => {
 
//     if (window.innerWidth <= 600) {
//         return
//     }
//     resetColours()
// }

// const resetColours = () => {
//     window.bgAnimStart = Date.now();
//     document.querySelectorAll(".bgAnim").forEach(e => {
//         e.style.animation = "none";
//     });
    
//     document.querySelectorAll(".fancyColour").forEach(e => {
//         e.style.animation = "none";
//         e.style.animationDelay = "none";
//     });
    
//     window.setTimeout(() => {
//         document.querySelectorAll(".bgAnim").forEach(e => {
//             e.style.animation = "";
//             e.style.animationDelay = "";
//         });
//         document.querySelectorAll(".fancyColour").forEach(e => {
//             e.style.animation = "";
//         });
//         syncGradient();
//         window.bgAnimStart = Date.now();
//     }, 10);
// } 

const getHashVars = (hash) => {
    var hashVars = {};

    var tempItems = hash.replace("#", "").split("&");
    if (tempItems.length) {
        tempItems.forEach(item => {
            var [key, value] = item.split("=");
            hashVars[key] = value || "";
        });
    }

    return hashVars;
}

const getSearchVars = (hash) => {
    var hashVars = {};

    var tempItems = hash.replace("?", "").split("&");
    if (tempItems.length) {
        tempItems.forEach(item => {
            var [key, value] = item.split("=");
            hashVars[key] = value || "";
        });
    }

    return hashVars;
}

const initSignalR = (method) => {
    return new Promise((resolve, reject) => {
        appInfo.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${appInfo.address}/centralHub`, { 
                transport: signalR.HttpTransportType[method]
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();
        appInfo.connection.start()
        .then(() => {
            console.info("Connected to server via signalR via " + appInfo.connection.connection.transport.constructor.name);
            resolve("Connected");
        }).catch((e) => {            
            console.error(e);
            console.error("Attempted method doesn't work.");
            alert("SignalR is not working. Live features may not work. Working to fix this. tried connecting via " + method)
            reject(e);
        });  
    });  
}

export {
    appInfo,
    sendRequest,
    helpers,
    helperVars,
    getHashVars,
    getSearchVars,
    initSignalR
};