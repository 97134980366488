// import logo from './logo.svg';
import React, { Component } from 'react';
import {
  	BrowserRouter as Router
} from "react-router-dom";

import "./styles/main.scss";

import logo from "./assets/logo.svg";
import logosingle from "./assets/logosingle.svg";

import { UserContext } from "./contexts/UserContext";
import LoggedIn from './switches/LoggedIn';
import NotLoggedIn from './switches/NotLoggedIn';
import NavBar from './reusable/NavBar';
import { appInfo } from './main';

class App extends Component {
    static contextType = UserContext;

  	constructor(props) {
        super(props);
        this.state = {
			secret: "",
			secretInterval: null
		}
  	}


  	render() { 
		return (
			<div>
				{this.context.init 
				// && this.context.initSockets 
				?
					
					<Router>
    	    			<div id={"main"} className={appInfo.devCSS ? "dev" : ""}>
    	    			    <img src={logosingle} alt={"regimensocial"} className={"bannerImageMob"}/>
    	    			    <img src={logo} alt={"regimensocial"} className={"bannerImage"}/>

								{this.context.loggedIn ? 
									<LoggedIn/> : 
									<NotLoggedIn/>							
								}

    	    			</div>
							
						<NavBar/>
					</Router>
					:
					
					<div><b>Please wait</b> If cert not enabled <a href={appInfo.address}>here</a></div>
				}
				<div id="godIHateSafari" className={"bgAnim"}>				
				</div>
			</div>
    	);
  	}
}
 
export default App;
