import TextMask from "./TextMask";

const Button = (props) => {
    
    
    return (
        <button className={`fancyButton ${props.className || ""}`} onClick={props.onClick} disabled={props.disabled} tabIndex={props.tabIndex}>
            
            <TextMask identifier={props.identifier} halfMast={true} dull={props.disabled}>{props.children}</TextMask>
            
        </button>
    )
}

export default Button;