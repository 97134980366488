var MainPage = props => {
    return (
        <div className={`mainPage ${props.active ? "active" : "inactive"}`} tabIndex={`${!props.active ? "-1" : ""}`}>
            {!props.hideTitle && <div className={"title"}>{props.title}</div>}
			<div className={`content ${props.page ? props.page : ""}`}>
                {props.specialBar && 
                    <div className={"specialBar"}>{props.specialBar}</div>
                }
                {!props.hideTitle && <div className={"division"}></div>}
				{props.children}
			</div>
        </div>
    ) 
}

export default MainPage;