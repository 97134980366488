import deepEqual from "deep-equal";
import { DateTime } from "luxon";
import { Component,  useState } from "react";
// import { Link } from "react-router-dom";
import { appInfo, helpers, sendRequest } from "../../main";
import BaseFile from "../../reactSVGs/BaseFile";
import MainPage from "../../reusable/MainPage";
import SlickContainer from "../../reusable/SlickContainer";

import Button from "../../reusable/Button";

import Miniselect from "../../reusable/Miniselect";
import React from "react";
import { UserContext } from "../../contexts/UserContext";
import Coolswitch from "../../reusable/Coolswitch";

const ViewDaySingle = (props) => {
    var date = props.day.date;
    var information = props.day.information;

    var [imageLoaded, setImageLoaded] = useState(false);
    var activeDay = information.exercises.length > 0;
    var possibleDay = activeDay;

    var dayButtons = (
        <>
            <div className={"dayButtons"}>      
                <Button className={"regular"} tabIndex={-1} disabled={!props.day.buttonInfo.prev} onClick={() => props.history.push({pathname: `/r/${date.regimenName}/${date.week}/${date.day - 1}`, noPull: true})}>Previous</Button>
                {/* <Button>Prev Week</Button>
                <Button>Next Week</Button> */}
                <Button className={"regular"} tabIndex={-1} disabled={!props.day.buttonInfo.next} onClick={() => props.history.push({pathname: `/r/${date.regimenName}/${date.week}/${date.day + 1}`, noPull: true})}>Next Day</Button>
            </div>
        </>  
    );

    var startButton = (
        <Button disabled={!possibleDay} className={"dayStart"} onClick={props.startDay}>
            {possibleDay && "Tap to Start This Day"}
            {!activeDay && "This is a rest day"}
        </Button>
    )

    return (
        <MainPage hideTitle={true} active={props.active && !props.params.fourthParam} page={"viewDaySingle ninety"}>
            <h2 style={{margin: "0"}}>Week {date.week}; Day {date.day}</h2>
            <div>{date.date}</div>

            <div className="top">
                {dayButtons}
                {startButton}
            </div>

            <div className={"dayInfoContainer"}>
                {!!information.weekInfo.info && (<div className="weekInfo">{information.weekInfo.info}</div>) }
                {!!information.info && (<div className="dayInfo">{information.info}</div>) }

                {!!(information.images.length > 1 && props.imageDisplay === "originalImage") && (<Miniselect className={"daySelect"} setStage={(n) => props.changeActiveImage(n-1)} amount={information.images.length} active={props.day.variable.activeImage + 1}/>)}
            </div>
            
            
            
            <span className={`${!(props.imageDisplay === "makeImage" || (!imageLoaded && props.imageDisplay !== "textOnly")) ? "hidden" : ""}`}>
                <BaseFile head={{width: "100%", 
                    style: {display: "block", maxWidth: "100%", margin: "0 auto"}
                }} exercise={!!information && information.exercises} 
                    {...props.day.imageInfo} captiontext={props.imageDisplay === "makeImage" ? ("sample text") : "loading original image"} prodno={props.ucState.prodno}
                />
            </span>
            <span className={`${(props.imageDisplay !== "originalImage" || !imageLoaded) ? "hidden" : ""}`}>
                <img style={{maxWidth: "100%", borderRadius: "15px",
                    display: "block", margin: "0 auto"
                }} onLoad={() => setImageLoaded(true)} src={appInfo.address + "/" + information.images[props.day.variable.activeImage]} alt=""/>
            </span>
            
            <span className={`${(props.imageDisplay !== "textOnly") ? "hidden" : ""}`}>
                {information.exercises.map((exercise, i) => {
                    return <div key={i}>{exercise.amount} {exercise.type}</div>
                })}
            </span>
            
            {props.imageDisplay !== "textOnly" && <div className="bottom">{startButton}{dayButtons}</div>}
            

        </MainPage>
    )
}

class ViewDay extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            presTable: [ // presentation table
                // {
                //     below: false,
                //     moved: true,
                //     day: {}
                // }
            ],
            viewedDays: [],
            timeInfo: {},
            stage: 1,
            transition: true,
            regimenList: [],
            moved: true,
            delay: 600,
            keyWait: null,
            overlapping: false,
            hello: "",
            imageDisplay: "originalImage",
            userControls: {
                prodno: "join the regimen!",
            },


            // BELOW SHOULD BE LAODED IN FROM LOCALSTORAGE TOO

            sessionOptions: "solo",
            friendSelect: "",
            sessionUsers: [],
            groupMode: false,
            groupName: "",
            //
            groupInfo: ""
            
        }
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleURLDisparity = this.handleURLDisparity.bind(this);
        this.keyFunc = this.keyFunc.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setUCState = this.setUCState.bind(this);
        this.changeActiveImage = this.changeActiveImage.bind(this);
        this.startDay = this.startDay.bind(this);
        this.selectGroup = this.selectGroup.bind(this);
        this.addFriend = this.addFriend.bind(this);
        this.removeFriend = this.removeFriend.bind(this);
    }
    
    addFriend(friendName = "") {
        if (this.state.sessionUsers.includes(friendName)) {
            return;
        }
        this.setState({
            sessionUsers: [...this.state.sessionUsers, friendName]
        });
    }

    removeFriend(friendName = "") {
        var newSessionUsers = [...this.state.sessionUsers].filter(i => i !== friendName);
        this.setState({
            sessionUsers: newSessionUsers
        });
    }

    selectGroup(groupName = "") {
        this.setState({
            groupName
        })
        sendRequest("api/manageGroup", {
            name: groupName
        }).then((data) => {
            console.log(data.data)
            this.setState({
                groupInfo: data.data
            })
        });
    }

    startDay() {
        console.log("hi")
        // this.setState({
        //     startingDay: true
        // })

        var data = this.state.presTable[0].day.date;

        this.props.history.push({pathname: `/r/${data.regimenName}/${data.week}/${data.day}/start`});
    }

    setUCState(key, value) {
        this.setState(prevState => ({
            userControls: {
                ...prevState.userControls,
                [key]: value
            }
        }))
    }

    changeActiveImage(n) {
        const newInfo = [...this.state.presTable];
        newInfo[0].day.variable.activeImage = n;
        this.setState({
            presTable: newInfo
        })
    }
    

    componentDidMount() {
        
        window.addEventListener("keydown", this.keyFunc, false);
        
        

        var regimenList = [];
        appInfo.regimenList.forEach(regimen => {
            regimenList.push(regimen.regimenName);
        });
        regimenList.push("invalid date", "please wait")

        this.setState({
            potentialUsers: this.context.friends.filter(x => x.userUsername.startsWith(this.state.friendSelect)).map((x) => ({valid: true, name: x.userUsername})),
            regimenList: regimenList
        })

        if (!this.props.active) {
            return
        }
        
        this.handleDayChange();
    }
    componentWillUnmount() {
        window.removeEventListener("keydown", this.keyFunc, false);
        
    }
    componentDidUpdate(prevProps, prevState) {
        if (!this.props.active) {
            return
        }

        if (prevProps.match.params.firstParam !== this.props.match.params.firstParam ||
        prevProps.match.params.secondParam !== this.props.match.params.secondParam ||
        prevProps.match.params.thirdParam !== this.props.match.params.thirdParam) {
            this.handleDayChange();
        }

        if (this.state.friendSelect !== prevState.friendSelect || !deepEqual(this.state.sessionUsers, prevState.sessionUsers)) {
            var newUsers = this.context.friends.filter(x => x.userUsername.startsWith(this.state.friendSelect.toLowerCase())).map((x) => ({valid: true, name: x.userUsername}));
            console.log(newUsers)

            newUsers = newUsers.filter(x => !this.state.sessionUsers.includes(x.name));
            // if (this.state.friendSelect !== prevState.friendSelect) {
            //     this.setState({
            //         potentialUsers: newUsers
            //     });
            //     return;
            // }

            if (this.state.friendSelect.length >= 4 && !this.state.sessionUsers.includes(this.state.friendSelect.toLowerCase()) && !newUsers.find(x => x.name === this.state.friendSelect.toLowerCase()) && this.state.friendSelect.toLowerCase() !== this.context.user.name) {
                helpers.checkUserExists(this.state.friendSelect.toLowerCase()).then(() => {
                    newUsers.unshift({valid: true, name: this.state.friendSelect.toLowerCase()});
                    this.setState({
                        potentialUsers: newUsers
                    })
                }).catch(() => {
                    if (!newUsers.length) {
                        newUsers.unshift({valid: false, name: this.state.friendSelect.toLowerCase() + " is not registered"});
                        this.setState({
                            potentialUsers: newUsers
                        })
                    }
                })
            } else {
                this.setState({
                    potentialUsers: newUsers
                })
            }
            
        }

        if (!deepEqual(prevState, this.state)) {


            var newPresTable = [...this.state.presTable];
            
            if (this.state.presTable[0] && !this.state.presTable[0].move && this.state.presTable[1]) {
                newPresTable[0].move = true;
                var self = this;
                this.setState({
                    transition: true,
                    presTable: newPresTable
                }, () => {
                    newPresTable.shift();
                    window.setTimeout(() => {
                        self.setState({
                            transition: false,
                            moved: true,
                            presTable: newPresTable
                        }, self.handleURLDisparity)
                    }, self.state.delay);
                });
            } 
        }
    }

    handleInputChange(event) {
		const target = event.target;
		var value;
		if (target.type === "number") {
			value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
		} else if (target.type === "checkbox") {
			value = target.checked;
		} else {
			value = target.value;
		}

    	const name = target.name;

    	this.setState({
		    [name]: value
		});
	}

    keyFunc(event) {

        if (!this.props.active) {
            return
        }

        if (!this.state.moved) {
            // this.setState({
            //     overlapping: true
            // })

            return;
        }

        var date = this.state.presTable[1] ? this.state.presTable[1].day.date : this.state.presTable[0].day.date;

        var week = date.week;
        var day = date.day;

        if ((event.code === "ArrowRight" || event.code === "KeyD" || event.code === "KeyL") && this.state.moved && !this.props.match.params.fourthParam) {

            if (this.state.presTable[1] ? !this.state.presTable[1].day.buttonInfo.next : !this.state.presTable[0].day.buttonInfo.next) {
                return;
            }

            if (day + 1 > 7) {
                day = 1;
                week = week + 1;
            } else {
                day++;
            }

            this.props.history.push({pathname: `/r/${date.regimenName}/${week}/${day}`, noPull: true});


        } else if ((event.code === "ArrowLeft" || event.code === "KeyA" || event.code === "KeyJ") && this.state.moved && !this.props.match.params.fourthParam) {

            if (this.state.presTable[1] ? !this.state.presTable[1].day.buttonInfo.prev : !this.state.presTable[0].day.buttonInfo.prev) {
                return;
            }

            if (day - 1 < 1) {
                day = 7;
                week = week - 1;
            } else {
                day--;
            }

            this.props.history.push({pathname: `/r/${date.regimenName}/${week}/${day}`, noPull: true});

        }
    }
    handleURLDisparity(data = this.state.presTable[0].day.date) {
        if ((this.props.match.params.firstParam !== (data.regimenName || data.name))
            || (this.props.match.params.secondParam.toString() !== data.week.toString())
            || (this.props.match.params.thirdParam.toString() !== data.day.toString())
        ) {
            this.props.history.push({pathname: `/r/${data.regimenName}/${data.week}/${data.day}`, noPull: true});
        }
    }
    handleDayChange() {

        // myArray = myArray.filter(function( obj ) {
        //     return obj.id !== id;
        // });

        if (!this.state.moved) {
            return;
        }

        var newDate = helpers.getRegimenDate(this.props.match.params.firstParam, this.props.match.params.secondParam, this.props.match.params.thirdParam);
        
        var maybeExists = this.state.presTable.find(x => x.day.date.date === newDate.date);

        if (maybeExists) {
            return;
        }

        if (!this.state.presTable.length) {
            this.handleURLDisparity(newDate);
        }

        var information;
        var currentDate;
        sendRequest("api/retrieveDay", {
            date: newDate.date
        }).then((d) => {
            information = d.data;
            currentDate = DateTime.fromISO(information.date)
        }).then(() => {
            this.setState({ presTable: [...this.state.presTable, {
                move: false,
                day: {
                    date: newDate,
                    information: information,
                    restDay: (information.exercises.length === 0),
                    imageInfo: {
                        weekno: "WEEK " + information.week,
                        dayno: "DAY " + information.day,
                        boldday: information.exercises.length,
                        regimenname: information.regimenName,
                        plaindaytext: `FOR DAY ${information.day}, ${currentDate.toFormat("EEEE").toUpperCase()}`,
                        date: currentDate.toFormat("dd MMM yyyy"),
                        weekdates: `${currentDate.startOf('week').toFormat("dd MMM")} - ${currentDate.endOf('week').toFormat("dd MMM")}`,
                        daydate: currentDate.toFormat("EEEE dd MMM"),
                        daystamp: `Week ${information.week}; Day ${information.day} (` + currentDate.toFormat("EEE") + ")"
                    },
                    buttonInfo: {
                        next: !(information.week === helpers.getRegimenInfo(information.regimen).weekAmount && information.day === 7),
                        prev: !(information.week === 1 && information.day === 1)
                    },
                    variable: {
                        activeImage: information.activityImage - 1
                    }
                }
            }], transition: true, moved: !this.state.presTable.length});
        })
        
        



        // {
        //     below: false,
        //     moved: true,
        //     day: {}
        // }

        
    }
    render() {
        var currentDay = this.state.presTable[0] ? this.state.presTable[0].day : null;
        return (
            <div>
                <SlickContainer
                    items = {
                        this.state.regimenList.map((r, i) => (
                            <MainPage key={i} active={this.props.active} title={r} />
                        ))
                    }
                    active={this.state.presTable[0] ? this.state.regimenList.findIndex(x => x === ((this.state.presTable[1] && this.state.presTable[1].day.date.regimenName) || this.state.presTable[0].day.date.regimenName)) + 1 : this.state.regimenList.length}
                    noMargin={true}
                    padding={"0"}
                />

                <SlickContainer
                    items = {
                        [(
                            <React.Fragment>
                                <div> Exercise display options{" "}
                                    <select name="imageDisplay" onChange={this.handleInputChange} value={this.state.imageDisplay} >
                                        <option value="originalImage">Show original image</option>
                                        <option value="makeImage">Regenerate image</option>
                                        <option value="textOnly">Text only</option>
                                    </select>
                                </div>
                                        
                                <div>{this.state.imageDisplay === "makeImage" && <span>Showing <b>reconstruction</b></span>}{this.state.imageDisplay === "originalImage" && <span>Showing <b>original image</b></span>}{this.state.imageDisplay === "textOnly" && <span>Showing <b>text only</b></span>}</div>
                                        
                                {this.state.presTable.map((item, index) => {
                                    var date = item.day.date;
                                    var nextContent = this.state.presTable[index + 1];
                                    var movingPosition = nextContent ? (nextContent.day.date.epoch > date.epoch ? 2 : 0) : 2; 
                                    var items = [
                                        null,
                                        <ViewDaySingle params={this.props.match.params} index={1} startDay={this.startDay} setUCState={this.setUCState} ucState={this.state.userControls} changeActiveImage={this.changeActiveImage} imageDisplay={this.state.imageDisplay} moved={this.state.moved} history={this.props.history} active={this.props.active} {...item}/>,
                                        null
                                    ]
                                
                                    if (nextContent) {
                                        items[movingPosition] = <ViewDaySingle params={this.props.match.params} index={movingPosition} startDay={this.startDay} setUCState={this.setUCState} changeActiveImage={this.changeActiveImage} ucState={this.state.userControls} imageDisplay={this.state.imageDisplay} moved={this.state.moved} history={this.props.history} active={false} {...nextContent}/>
                                    }
                                
                                    if (index > 0) {
                                        return null;
                                    }
                                    
                                    return (
                                        <SlickContainer
                                            key = {index}
                                            items = {items}
                                            active={!item.move ? 2 : movingPosition + 1}
                                            noMargin={true}
                                            padding={"0"}
                                            delay={this.state.delay+"ms"}
                                            noTransition={!this.state.transition}
                                        />
                                        
                                    )
                                })}

                            </React.Fragment>
                        ),
                        (
                            (!!currentDay) && (
                                (
                                    <div className={"viewDayOptions ninety"}>
                                        You want to start <b>Week {currentDay.date.week}; Day {currentDay.date.day}</b> of <span className="sCaps">{currentDay.date.regimenName}</span>.
                                        <div className={"textContent"}>Do you want to do this exercise on your own?{" "}
                                            <select name="sessionOptions" onChange={this.handleInputChange} value={this.state.sessionOptions}>
                                                <option value="solo">Yes</option>
                                                <option value="friends">No, with friends</option>
                                            </select>
                                        </div>
                                        <div className={"textContent"}>
                                            <b>
                                                {this.state.sessionOptions === "solo" && "You're doing this day by yourself."}
                                                {this.state.sessionOptions === "friends" && "You're doing this day with friends."}
                                            </b> You can change this above.
                                        </div>
                                        {this.state.sessionOptions === "friends" && (
                                            <>
                                                <div className={"new textContent"}>
                                                    {!this.context.user.groups.length ? "Add users below" : (
                                                        <div>
                                                            {/* What would you like to do? (tap to change)
                                                            <ul className={"noListBullet"}>
                                                                <li>Invite individual users</li>
                                                                <li>Invite an entire group</li>
                                                            </ul> */}

                                                            <Coolswitch items={[
                                                                "Invite users",
                                                                "Invite group"
                                                            ]} active={!this.state.groupMode ? 0 : 1} switchMode={() => this.setState({groupMode: !this.state.groupMode})} />
                                                        </div>
                                                    )}
                                                </div>

                                                <SlickContainer
                                                    items = {
                                                        [
                                                            <React.Fragment>
                                                                <input placeholder={"enter usernames here"} maxLength={36} className={"friendSelect"} name={"friendSelect"} value={this.state.friendSelect} onChange={this.handleInputChange} />
                                                                {!!this.state.potentialUsers.length && <div><b>Suggested users</b>, tap to add any.</div>}
                                                                {this.state.potentialUsers.map((friend, i) => 
                                                                (<Button className={"sepButtons"} key={i} onClick={() => this.addFriend(friend.name)} disabled={!friend.valid}>{friend.name}</Button>))}

                                                                {!!this.state.sessionUsers.length && 
                                                                    <div className={"groupInfo"}>
                                                                        <div><b>The following users will be requested</b>, tap to remove any.</div>
                                                                        {this.state.sessionUsers.map((x, i) => <Button onClick={() => this.removeFriend(x)} className={"sepButtons"} key={i}>{x}</Button>)}
                                                                    </div>
                                                                }

                                                            </React.Fragment>,
                                                            <React.Fragment>
                                                                <div><b>Your groups</b></div>
                                                                {this.context.user.groups.map((group, i) => 
                                                                (<Button className={"sepButtons"} key={i} onClick={() => this.selectGroup(group)}>{group}</Button>))}
                                                                {!!this.state.groupInfo && 
                                                                    <div className={"groupInfo"}>
                                                                        <div className={"groupName"}>{this.state.groupName}</div>
                                                                        Users:
                                                                        <ul className={"noListPadding"}>
                                                                            {this.state.groupInfo.users.map((x, i) => <li key={i}>{x.name}</li>)}
                                                                        </ul>
                                                                    </div>}
                                                            </React.Fragment>
                                                        ]
                                                    }
                                                    active={!this.state.groupMode ? 1 : 2}
                                                    padding={"0"}
                                                />
                                            </>
                                            
                                        )}
                                        <Button className={"startButton"} disabled={!(this.state.sessionOptions === "solo" || (this.state.groupMode && this.state.groupInfo) || (!this.state.groupMode && this.state.sessionUsers.length))}>
                                            Begin Session
                                        </Button>
                                    </div>
                                )
                            )  
                        )
                        ]
                    }
                    active={!this.props.match.params.fourthParam ? 1 : (this.props.match.params.fourthParam === "start" ? 2 : 3)}
                    noMargin={true}
                    padding={"0"}
                />

                

            </div>
            
        );
    }
}
 
export default ViewDay;
export {
    ViewDaySingle
}