import {
    Switch,
    Route,
    // Redirect
} from "react-router-dom";

import Central from "../Central";

// import Home from "../pages/loggedIn/Home";
// import Welcome from "../pages/loggedIn/Welcome";
// import Profile from "../pages/loggedIn/Profile";
// import ViewUser from "../pages/loggedIn/ViewUser";

// var LoggedIn = () =>
// (
//     <Switch>
//         <Route exact path="/"
//         	component={Home}
//         />
//         <Route path="/welcome"
//             component={Welcome}
//         />
//         <Route path="/profile"
//             component={Profile}
//         />
//         <Route path="/u/:friendName"
//             component={ViewUser}
//         />
//         <Route
//             path="/g/:friendName"
//             render={(props) => (
//                 <ViewUser {...props} isGroup={true} />
//             )}
//         />
//         <Redirect to="/"/>
//     </Switch>
// );

var LoggedIn = () => 
(
    <Switch>
        <Route
            path="/:pageAddress?/:firstParam?/:secondParam?/:thirdParam?/:fourthParam?"
            component={Central}
        />
    </Switch>
)

export default LoggedIn;