import React, { createContext, Component } from "react";


// import axios from "axios"

import { 
    appInfo,
    initSignalR,
    // appInfo,
    // appInfo, 
    sendRequest 
} from "../main.js";

export const UserContext = createContext();

class UserContextProvider extends Component {

    constructor(props) {
		super(props);
        this.state = {
            user: {
                name: "User"
            },
            loggedIn: false,
            init: false,
            initSockets: false,
            friends: [],
            inboundFriends: [],
            outboundFriends: []
        }

        this.updateState = this.updateState.bind(this);
        this.handleWebSocket = this.handleWebSocket.bind(this);
        this.getFriends = this.getFriends.bind(this);
	}
    

    updateState(name, value, callback) {
        this.setState({
            [name]: value
        }, () => {
            if (callback) {
                callback();
            }
        })
    }

    handleWebSocket(name, data) {
        switch (name) {
            case "profileUpdate":
                this.setState({
                    user: data
                })
                break;
            case "friendRequest":
                this.getFriends();
                break;
            case "friendInfoUpdate":
                var newFriends = this.state.friends;
                console.log(data.picture);
                newFriends[newFriends.findIndex(e => e.userUsername === data.name)].friend = data;
                this.setState({
                    friends: newFriends
                });
                break;
        
            default:
                break;
        }
    }

    getFriends() {
        var self = this;
        sendRequest("api/retrieveFriends")
        .then((r) => {

            self.setState({
                friends: r.data
            });
        })

        sendRequest("api/retrieveFriends", {
            action: "requests"
        })
        .then((r) => {

            self.setState({
                inboundFriends: r.data
            });
        })

        sendRequest("api/retrieveFriends", {
            action: "myRequests"
        })
        .then((r) => {

            self.setState({
                outboundFriends: r.data
            });
        })
    }

    componentDidMount() {
        var self = this;
        console.log(
            "%cif not logged in, there will be errors below, this is expected and will be fixed before release.",
            "color:black;font-family:Segoe UI,system-ui;font-size:2rem;font-variant:small-caps;-webkit-text-stroke: 1px black;font-weight:bold"
        );
        
        sendRequest("api/whoami")
        .then((r) => {
            
            self.setState({
                user: r.data,
                loggedIn: true
            });
        })
        .catch((r) => {
            self.setState({
                loggedIn: false
            });
        }).then(() => {
            self.setState({
                init: true
            })

            // LOGIN OKAY WRTIE CODE BELOW

            self.getFriends();


        });

        // WebSockets
        // ServerSentEvents
        // LongPolling
        initSignalR("ServerSentEvents").then(() => {
            this.setState({
                initSockets: true
            })
            appInfo.connection.on("profileUpdate", (data) => this.handleWebSocket("profileUpdate", data));
            appInfo.connection.on("friendRequest", (message) => this.handleWebSocket("friendRequest", message));
            appInfo.connection.on("friendInfoUpdate", (message) => this.handleWebSocket("friendInfoUpdate", message));
        }).catch(() => {
            // handle error 
        });

        window.initSignalR = (method = "ServerSentEvents") => initSignalR(method).then(() => {
            this.setState({
                initSockets: true
            })
            appInfo.connection.on("profileUpdate", (data) => this.handleWebSocket("profileUpdate", data));
            appInfo.connection.on("friendRequest", (message) => this.handleWebSocket("friendRequest", message));
            appInfo.connection.on("friendInfoUpdate", (message) => this.handleWebSocket("friendInfoUpdate", message));
        }).catch(() => {
            // handle error 
        });


    }

    componentWillUnmount() {
        this.handleWebSocket = () => "";
    }

    render() { 
        return (
            <UserContext.Provider value={{...this.state, updateState: this.updateState}}> 
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
 
export default UserContextProvider;