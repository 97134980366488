import { Component } from "react";
import { UserContext } from "../contexts/UserContext";
import MainPage from "../reusable/MainPage";
import { Link } from "react-router-dom";
import { helpers, sendRequest } from "../main";
import Button from "../reusable/Button";
import { DateTime } from "luxon";

class Social extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {  }
        this.manageUser = this.manageUser.bind(this);
    }
    manageUser(action, username) {
        if (action === "view") {
            this.props.history.push("/u/" + username);
        } else {
            sendRequest("api/specificFriend", {
                friendUsername: username,
                action
            }).then((_r) => {
                // we can rely on websockets to sort this shit out
            })
        }
    }
    render() { 
        return ( 
            <MainPage title={"social"} page={"social"} active={this.props.active}>
                Welcome <b>@{this.context.user.name}</b>!
                {!!this.context.friends.length ? (
                    <div>
                        
                        You have {this.context.friends.length} friends.
                        
                        {this.context.friends.map((u, i) => {
                            
                            var f = u.userUsername;
                            var me = f === this.context.user.name;
                            return (
                                <div key={f} className={"editMembers"}>
                                    
                                    <div className={"name"}>
                                        <Link to={!me ? "/u/" + f : "/profile"} {...this.props.enableInput}>@{f}</Link>
                                    </div>

                                    <div className={"onlineStamp"}>{u.friend.online ? <b>online currently</b>: "offline"}</div> 
                                    
                                    <div className={"timestamp"} data-tip data-for={`userTimestamp-${f}`}>
                                        {DateTime.fromISO(u.timestamp).toRelative()}
                                    </div>
                                    

                                    <div className={"buttonContainer"}> 
                                        <div className={"item button"}>
                                            <Button identifier={"gMBprofile"} onClick={() => this.manageUser("view", f)}>
                                                View Profile
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            );

                            // return (
                            //     <li key={i}>
                            //         <Link to={`/u/${friend.userUsername}`}>@{friend.userUsername}</Link>
                            //     </li>
                            // )
                        })}
                    </div>
                ): <div>You don't have any friends at the moment.</div>}
                
                {!!this.context.inboundFriends.length && (
                    <div>
                        You have {this.context.inboundFriends.length} friend {helpers.returnPlural(this.context.inboundFriends.length, "request")}.
                        
                        {this.context.inboundFriends.map((u, i) => {
                            var f = u.userUsername;
                            var me = f === this.context.user.name;
                            
                            return (
                                <div key={f} className={"editMembers"}>
                                    
                                    <div className={"name"}>
                                        <Link to={!me ? "/u/" + f : "/profile"} {...this.props.enableInput}>@{f}</Link> 
                                    </div>
                                    
                                    <div className={"timestamp"} data-tip data-for={`userTimestamp-${f}`}>
                                        {DateTime.fromISO(u.timestamp).toRelative()}
                                    </div>
                                    

                                    <div className={"buttonContainer"}> 
                                        <div className={"item button"}>
                                            <Button identifier={"gMBprofile"} onClick={() => this.manageUser("makeFriend", f)}>
                                                Accept
                                            </Button>
                                        </div>
                                        <div className={"item button"}>
                                            <Button identifier={"gMBprofile"} onClick={() => this.manageUser("view", f)}>
                                                View
                                            </Button>
                                        </div>
                                        
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}

                {!!this.context.outboundFriends.length && (
                    <div>
                        {this.context.outboundFriends.length} of your friend requests {helpers.returnPlural(this.context.outboundFriends.length, "has")} not been responded to.
                        {this.context.outboundFriends.map((u, i) => {
                            var f = u.friendUsername;
                            var me = f === this.context.user.name;
                            
                            return (
                                <div key={f} className={"editMembers"}>
                                    
                                    <div className={"name"}>
                                        <Link to={!me ? "/u/" + f : "/profile"} {...this.props.enableInput}>@{f}</Link> 
                                    </div>
                                    
                                    <div className={"timestamp"} data-tip data-for={`userTimestamp-${f}`}>
                                        {DateTime.fromISO(u.timestamp).toRelative()}
                                    </div>
                                    

                                    <div className={"buttonContainer"}> 
                                        
                                        <div className={"item button"}>
                                            <Button identifier={"gMBprofile"} onClick={() => this.manageUser("view", f)}>
                                                View
                                            </Button>
                                        </div>
                                        <div className={"item button"}>
                                            <Button identifier={"gMBprofile"} onClick={() => this.manageUser("endFriendship", f)}>
                                                Cancel
                                            </Button>
                                        </div>
                                        
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </MainPage> 
        );
    }
}
 
export default Social;