import Home from "../pages/notLoggedIn/Home";

import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Enter from "../pages/notLoggedIn/Enter";

var NotLoggedIn = () =>
(
    <Switch>
        <Route exact path="/"
        	component={Home}
        />
        <Route path="/enter"
            component={Enter}
        />
        <Redirect to="/"/>
    </Switch>
);

export default NotLoggedIn;