import { Component } from "react";
import MainPage from "../../reusable/MainPage";
// import FancyColour from "../../reusable/FancyColour";
import { Link } from "react-router-dom";
import TextMask from "../../reusable/TextMask";
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            stage: 1
        }
        this.setStateBasic = this.setStateBasic.bind(this);
    }
    setStateBasic(val, name) {
        this.setState({
            [name]: val
        })
    }
    render() { 
        return ( 
            <MainPage active={this.props.active} title={"home"}>
                <TextMask dull={false} onClick={() => console.log("hi")}>
                    The quick brown fox jumps over the lazy dog.
                </TextMask>
                <div>
                    Welcome to <span className={"logo"}>regimensocial</span>
                </div>
                
                <Link to={"/u/jamiec"} {...this.props.enableInput}>test</Link>
            </MainPage> 
        );
    }
}
 
export default Home;