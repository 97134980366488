import { Component, createRef } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { UserContext } from "../../contexts/UserContext";
import { appInfo, helpers, helperVars, sendRequest } from "../../main";
import MainPage from "../../reusable/MainPage";
import Button from "../../reusable/Button";
import SlickContainer from "../../reusable/SlickContainer";
import TextareaAutosize from 'react-textarea-autosize';
import deepEqual from "deep-equal";
import TextMask from "../../reusable/TextMask";
import ReactTooltip from "react-tooltip";

class ViewGroup extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = { 
            friend: "",
            friendInfo: {},
            group: "",
            groupInfo: {},
            exists: "unproven",
            removeStart: false,
            bio: "",
            editingGroup: false,
            newImage: ""
        }
        this.handleWebSocket = this.handleWebSocket.bind(this);
        this.retrieveGroupInfo = this.retrieveGroupInfo.bind(this);
        this.editGroup = this.editGroup.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.fileUploader = createRef();
    }
    handleInputChange(event) {
		const target = event.target;
		var value;
		if (target.type === "number") {
			value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
		} else if (target.type === "checkbox") {
			value = target.checked;
		} else {
			value = target.value;
		}

    	const name = target.name;
		
        if (name === "privacySettings") {
            value = parseInt(value);
        }

    	this.setState({
		    [name]: value
		});
	}
    handleFileChange(e) {
        var self = this;
        helpers.readFileDataAsBase64(e, false).then((file) => {
            self.setState({
                newImage: file
            })
        }).catch((err) => {
            console.log(err)
        })
    }
    handleWebSocket(name, data) {
        switch (name) {
            case "groupTrack":
                console.log(data)
                if (typeof data === "object") {
                    // console.log(data)

                    if (data.name !== this.state.group) {
                        return;
                    }

                    
                    var groupInfo = data;
                    var userInGroup = (groupInfo && groupInfo.users) ? groupInfo.users.find(i => i.name === this.context.user.name) : null;
                    var groupAdmin = !!(groupInfo && groupInfo.partOfGroup && (userInGroup.permissionLevel === 1 || userInGroup.permissionLevel === 2));

                    this.setState({
                        groupInfo: data,
                        editingGroup: (groupAdmin) ? this.state.editingGroup : false
                    });
                }
                break;
        
            default:
                break;
        }
    }
    componentDidMount() {
        appInfo.connection.on("groupTrack", (data) => this.handleWebSocket("groupTrack", data));
        
        if (!this.props.active) {
            return;
        }
        
        var friendName = this.props.match.params.firstParam;
        if (helpers.validUsername(friendName)) {
            this.setState({
                group: this.props.match.params.firstParam
            }, this.retrieveGroupInfo);
        }
    }
    componentWillUnmount() {
        this.handleWebSocket = () => "";
        appInfo.connection.invoke("GroupTrack", "").catch((err) => {
            console.error(err);
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (!this.props.active) {
            return;
        }

        if ((prevProps.match.params.firstParam !== this.props.match.params.firstParam) && helpers.validUsername(this.props.match.params.firstParam)) {
            this.setState({
                group: this.props.match.params.firstParam
            }, this.retrieveGroupInfo);
        }

        if (!deepEqual(this.state, prevState)) {

            // if (this.state.group !== prevState.group) {
            //     appInfo.connection.invoke("GroupTrack", "").catch((err) => {
            //         console.error(err);
            //     });
            //     appInfo.connection.invoke("GroupTrack", this.state.group).catch((err) => {
            //         console.error(err);
            //     });
            // }

            var groupInfo = this.state.groupInfo;
            var userInGroup = (groupInfo && groupInfo.users) ? groupInfo.users.find(i => i.name === this.context.user.name) : null;
            var groupAdmin = !!(groupInfo && groupInfo.partOfGroup && (userInGroup.permissionLevel === 1 || userInGroup.permissionLevel === 2));
            
            var groupOwner = !!(groupInfo && groupInfo.partOfGroup && (userInGroup.permissionLevel === 1));

            this.props.handleSpecialBar(this.props.pageName,
                {
                    pageName: "profile",
                    buttonHubs: [
                        {
                            active: this.state.exists === "doesExist" && !groupInfo.partOfGroup,
                            buttons: [
                                {
                                    text: "Join",
                                    function: () => this.retrieveGroupInfo("request"),
                                    active: (groupInfo && !groupInfo.partOfGroup) && !(groupInfo.requestedUsers && groupInfo.requestedUsers.length),
                                },
                            ]
                        },
                        {
                            active: groupAdmin,
                            buttons: [
                                {
                                    text: this.state.editingGroup ? "Exit" : "Edit",
                                    function: () => this.editGroup(groupAdmin),
                                    active: (groupAdmin),
                                },
                                {
                                    text: "Save",
                                    function: () => this.retrieveGroupInfo("update"),
                                    active: (this.state.editingGroup && (this.state.bio !== groupInfo.bio || this.state.newImage)),
                                },
                                
                            ]
                        },
                        {
                            active: !this.state.editingGroup && groupInfo.partOfGroup && !groupOwner,
                            buttons: [
                                {
                                    ignore: true,
                                    active: false
                                },
                                {
                                    text: !this.state.removeStart ? "Leave Group" : "Are you sure?",
                                    function: !this.state.removeStart ? () => this.setState({removeStart: true}) : () => this.retrieveGroupInfo("leave"),
                                    active: groupInfo.partOfGroup,
                                },
                                
                            ]
                        }
                    ]
                }
            )
        }
    }
    retrieveGroupInfo(action = "", special = "") {
        sendRequest("api/manageGroup", {
            name: this.state.group,
            action,
            special,
            bio: this.state.bio,
			picture: this.state.newImage.split(",")[1] || ""
        }).then((data) => {
            console.log(data)

            appInfo.connection.invoke("GroupTrack", this.state.group).catch((err) => {
                console.error(err);
            });

            if (!data.data && (action !== "check" || action !== "")) {
                this.retrieveGroupInfo();
                return
            }

            this.setState({
                exists: "doesExist",
                groupInfo: data.data,
                bio: data.data.bio,
                removeStart: false,
                newImage: "",
            }, () => {
                return true;
            })
        }).catch(() => {
            if (!action || action === "check") {
                this.setState({
                    exists: "doesNot"
                }, () => {
                    return false;
                })
            }
        })
    }
    editGroup(allowed) {
        if (!allowed) {
            return;
        }
        this.setState({
            editingGroup: !this.state.editingGroup
        })
    }
    render() { 
        var groupInfo = this.state.groupInfo;
        var userInGroup = (groupInfo && groupInfo.users) ? groupInfo.users.find(i => i.name === this.context.user.name) : null;
        var groupAdmin = !!(groupInfo && groupInfo.partOfGroup && (userInGroup.permissionLevel === 1 || userInGroup.permissionLevel === 2));
        var groupOwner = !!(groupInfo && groupInfo.partOfGroup && (userInGroup.permissionLevel === 1));

        if (this.state.exists === "unproven") {
            return <MainPage active={this.props.active} title={"loading"} />;
        }

        var groupPage = (
            <MainPage active={this.props.active} title={"groups"} page={"profile"}>
                {   
                    (this.state.exists !== "doesNot" && groupInfo) ?
                    <div>
                        <div className={"username special " + groupInfo.special} style={groupInfo.partOfGroup ? {fontWeight: "bold"} : {}}>{groupInfo.name}</div>
                        {!!groupInfo.bio && <div className={"bio"}>"{groupInfo.bio}"</div>}
                        <div className={"timestamp"}>{!!groupInfo.timestamp ? `Created ${DateTime.fromISO(groupInfo.timestamp).toRelative()}` : <span>A member of <span className={"logo"}>regimensocial</span></span>}</div>
                        <img
                            className={"standard profilePic"} 
                            src={helpers.getGroupPicture(groupInfo.picture, groupInfo.pictureCount)}
                            alt={"profile picture of "+groupInfo.name}
                        />
                        {(!!groupInfo.users && !!groupInfo.users.length) &&
                            <div className={"groupMenu"}>
                            
                                <div>
                                    <b>{this.state.group}</b> has {groupInfo.users.length} user{groupInfo.users.length > 1 && "s"}:
                                    <ul className={"noListPadding"}>
                                        {groupInfo.users.map((u) => {
                                            var f = u.name;
                                            var me = f === this.context.user.name;
                                            return <li key={f} className={`${me ? "thisIsMe" : ""}`}><Link to={!me ? "/u/" + f : "/profile"} {...this.props.enableInput}>{f}</Link> ({helpers.getPermission(u.permissionLevel)})</li>;
                                        })}
                                    </ul>
                                </div>
                            </div>
                        }
                        

                        {(!!groupInfo.requestedUsers && !!groupInfo.requestedUsers.length) &&
                            <div className={"groupMenu"}>
                                There {groupInfo.requestedUsers.length > 1 ? "are" : "is"} {groupInfo.requestedUsers.length} request{groupInfo.requestedUsers.length > 1 && "s"} to join <b>{this.state.group}</b>:
                                    {groupInfo.requestedUsers.map((u) => {
                                        var f = u.name;
                                        var me = f === this.context.user.name;
                                        console.log(u)
                                        return (
                                            <div key={f} className={"editMembers"}>
                                                
                                                <div className={"name"}>
                                                    <Link to={!me ? "/u/" + f : "/profile"} {...this.props.enableInput}>@{f}</Link> 
                                                </div>
                                                
                                                <ReactTooltip id={`userTimestamp-${f}`} effect={"solid"} backgroundColor={"black"} textColor={"white"} border={true}>
                                                    <b>{DateTime.fromISO(u.timestamp).toFormat(helperVars.timeFormat)}</b>
                                                </ReactTooltip>
                                                <div className={"timestamp"} data-tip data-for={`userTimestamp-${f}`}>
                                                    {DateTime.fromISO(u.timestamp).toRelative()}
                                                </div>

                                                {
                                                    !!groupAdmin &&
                                                        (
                                                            <div className={"buttonContainer"}> 
                                                                <div className={"item button"}>
                                                                    <Button identifier={"gMBaccept"} onClick={() => this.retrieveGroupInfo("accept", f)}>
                                                                        Accept
                                                                    </Button>
                                                                </div>
                                                                <div className={"item button"}>
                                                                    <Button identifier={"gMBignore"} onClick={() => this.retrieveGroupInfo("removeUser", f)}>
                                                                        Ignore
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )
                                                }
                                                
                                            </div>
                                        );
                                    })}
                            </div>
                        }

                    </div>
                    :
                    <div>
                        <span>The group <b>{this.state.group}</b> doesn't appear to exist on regimensocial.</span>
                        <div><Button onClick={() => this.retrieveGroupInfo("create")}>Create "{this.state.group}"</Button></div>
                    </div>
                }
            </MainPage>
        )

        var groupEditPage = (
            groupAdmin && (
                <MainPage active={this.props.active} title={"editing group"} page={"profile"}>
                    <div className={"bio editing"}>
                        <TextareaAutosize
                            className={"regularText"}
                            name={"bio"}
                            value={this.state.bio}
                            onChange={this.handleInputChange}
                            disabled={!this.state.editingGroup}
                            {...this.props.enableInput}
                        />
                    
                    </div>
                    <input 
                        type="file"
                        ref={this.fileUploader} 
                        className={"hidden"}
                        name={"newImage"}
                        accept="image/png"
                        disabled={!this.state.editing}
                        onChange={this.handleFileChange}
                        {...this.props.enableInput}
                    />

                    <div className={"profilePicParent"}>
                        <img
                            className={`standard profilePicEditing ${!this.state.newImage ? "editing" : ""}`} 
                            src={this.state.newImage || helpers.getGroupPicture(groupInfo.picture, groupInfo.pictureCount)}
                            alt={"profile picture of "+ groupInfo.name}
                            onClick={() => this.fileUploader.current.click()}
                        />
                        <div className={`specialText ${this.state.newImage ? "partHidden" : ""}`} onClick={() => this.fileUploader.current.click()}>
                            <TextMask dull={this.state.newImage} bg={true}>
                                {!this.state.newImage ? "Tap to upload" : "Tap to change"}
                            </TextMask>
                        </div>

                    </div>

                    <MainPage active={this.props.active} title={"group members"} page={"profile"}>
                        <div className={"info"}>There are <b>3</b> permission ranks: <b>Member</b>; <b>Admin</b>; and <b>Owner</b>.</div>
                        <div className={"info"}>A <b>member</b> is part of the group, but cannot make any changes.</div>
                        <div className={"info"}>An <b>admin</b> can approve <b>requested members</b>, kick <b>members</b> from the group, and also make changes to the group, such as the bio and profile picture. </div>
                        <div className={"info"}>The <b>owner</b> can promote people to admin and demote admins.</div>
                        <div>
                            {groupInfo.users.map((u) => {
                                var f = u.name;


                                // Not too happy with how this works but it'll do for now

                                var me = f === this.context.user.name;
                                
                                var enablePromote = false, enableDemote = false, enableDelete = false;

                                if (groupOwner) {
                                    enablePromote = true;
                                    enableDemote = true;
                                    enableDelete = true;
                                }

                                if (me) {
                                    enablePromote = false;
                                    if (groupOwner) {
                                        enableDemote = false;
                                    }
                                } 

                                if (u.permissionLevel === 1 || u.permissionLevel === 2) {
                                    enablePromote = false; enableDemote = false; enableDelete = false
                                    if (groupOwner && !me) {
                                        enableDemote = true;
                                    }
                                }

                                if (u.permissionLevel === 3) {
                                    enableDelete = true;
                                    enableDemote = false;
                                }
                                
                                if (groupOwner && !me) {
                                    enableDelete = true;
                                }

                                if (!groupOwner && me) {
                                    enableDelete = true;
                                }
                                



                                return (
                                    <div key={f} className={"editMembers"}>
                                                
                                        <div className={`name ${me ? "thisIsMe" : ""}`}>
                                            <Link to={!me ? "/u/" + f : "/profile"} {...this.props.enableInput}>@{f}</Link> 
                                        </div>

                                        <div className={"timestamp"}>
                                            <b>{helpers.getPermission(u.permissionLevel, true)}</b>
                                        </div>
                                        <div className={"buttonContainer"}> 
                                            <div className={`item button ${enablePromote ? "active" : "inactive"}`}>
                                                <Button identifier={"gMBpromote"} onClick={() => this.retrieveGroupInfo("promote", f)} disabled={!enablePromote}>
                                                    Promote
                                                </Button>
                                            </div>
                                            <div className={`item button ${enableDemote ? "active" : "inactive"}`}>
                                                <Button identifier={"gMBdemote"} onClick={() => this.retrieveGroupInfo("demote", f)} disabled={!enableDemote}>
                                                    Demote
                                                </Button>
                                            </div>
                                            <div className={`item button ${(me && !groupOwner) || enableDelete ? "active" : "inactive"}`}>
                                                <Button identifier={"gMBkill"} onClick={!me ? () => this.retrieveGroupInfo("removeUser", f) : () => this.retrieveGroupInfo("leave")} disabled={!enableDelete}>
                                                    {!me ? "Kill" : "Leave"}
                                                </Button>
                                            </div>
                                        </div>

                                    </div>
                                );
                            })}
                        </div>
                    </MainPage>

                    <MainPage active={this.props.active} title={"requested members"} page={"profile"}>
                        {(!!groupInfo.requestedUsers && !!groupInfo.requestedUsers.length) &&
                            <div className={"groupMenu"}>
                                There {groupInfo.requestedUsers.length > 1 ? "are" : "is"} {groupInfo.requestedUsers.length} request{groupInfo.requestedUsers.length > 1 && "s"} to join <b>{this.state.group}</b>:
                                    {groupInfo.requestedUsers.map((u) => {
                                        var f = u.name;
                                        console.log(u)
                                        
                                        var me = f === this.context.user.name;
                                        
                                        return (
                                            <div key={f} className={"editMembers"}>
                                                
                                                <div className={`name ${me ? "thisIsMe" : ""}`}>
                                                    <Link to={!me ? "/u/" + f : "/profile"} {...this.props.enableInput}>@{f}</Link> 
                                                </div>
                                                
                                                <ReactTooltip id={`userTimestamp-${f}`} effect={"solid"} backgroundColor={"black"} textColor={"white"} border={true}>
                                                    <b>{DateTime.fromISO(u.timestamp).toFormat(helperVars.timeFormat)}</b>
                                                </ReactTooltip>
                                                <div className={"timestamp"} data-tip data-for={`userTimestamp-${f}`}>
                                                    {DateTime.fromISO(u.timestamp).toRelative()}
                                                </div>

                                                {
                                                    !!groupAdmin &&
                                                        (
                                                            <div className={"buttonContainer"}> 
                                                                <div className={"item button"}>
                                                                    <Button identifier={"gMBaccept"} onClick={() => this.retrieveGroupInfo("accept", f)}>
                                                                        Accept
                                                                    </Button>
                                                                </div>
                                                                <div className={"item button"}>
                                                                    <Button identifier={"gMBignore"} onClick={() => this.retrieveGroupInfo("removeUser", f)}>
                                                                        Ignore
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )
                                                }
                                                
                                            </div>
                                        );
                                    })}
                            </div>
                        }
                    </MainPage>
                    
                </MainPage>
            )
        )
        
        

        // this.props.handleSpecialBar(
        //     (<SpecialBar className={`profile active`}>
        //             <Button onClick={() => this.retrieveGroupInfo("request")} className={`${(groupInfo && !groupInfo.partOfGroup) && !(groupInfo.requestedUsers && groupInfo.requestedUsers.length)  ? "active" : ""}`}>
        //                 Join
        //             </Button>
        //             <Button onClick={() => this.retrieveGroupInfo("update")} className={`first ${
        //                 (this.state.editingGroup && (this.state.bio !== groupInfo.bio || this.state.newImage))  ? "active" : ""}`
        //             }>
        //                 Save
        //             </Button>
        //             <Button onClick={() => this.editGroup(groupAdmin)} className={`${groupAdmin ? "active" : ""}`}>
        //                 {this.state.editingGroup ? "Cancel" : "Edit"}
        //             </Button>
        //     </SpecialBar>)
        // );

        return (
            <div>
                
                <SlickContainer
                    items = {
                        [
                            groupPage,
                            groupEditPage
                        ]
                    }
                    active={!(groupAdmin && this.state.editingGroup) ? 1 : 2}
                    noMargin={true}
                    padding={"0"}
                />
            
            </div>
        )
    }
}
 
export default ViewGroup;