const SlickContainer = (props) => {
    const stage = !props.active ? 1 : props.active; 
    var headPadding = props.headPadding || 0;
    var padding = props.padding + (headPadding) || `${10 + (headPadding)}px`;
    return (
        <div className={`slickContainer ${props.className || ""} ${props.noTransition ? "noTransition" : ""}`}>
            <div className={"parent"} style={props.noMargin && {marginTop: "0"}}>
                {props.header ? 
                    <div style={{
                        paddingTop: padding,
                        paddingLeft: padding,
                        paddingRight: padding,
                        paddingBottom: props.paddingBottom || padding
                    }}>
                        {props.header}
                    </div> 
                : null}
                <div className={`central mode${stage}`} style={
                    {
                        transform: `translate(${stage > 1 ? `-${(stage - 1) * 100}%)` : "0"}`,
                        transition: !props.noTransition ?  `transform ${props.delay || "600ms"}` : ""
                    }
                }>
                    {props.items.map((e, i) => <div key={i} style={
                        {
                            padding: props.padding || "10px",
                            transition: !props.noTransition ? `opacity ${props.fadeTime || "700ms"}` : ""
                        }
                    } className={`object zone${i+1} ${stage === i+1 ? "active" : ""}`}>{e}</div>)}
                </div>
            </div>
        </div>
    )
}

export default SlickContainer;