import { useEffect, useState } from "react";
import { useRef } from "react";
import { helpers } from "../main";
import { v4 as uuidv4 } from 'uuid';

const TextMask = (props) => {
    const uniqueIdentifier = useRef(`${props.identifier || "unidentified"}-${uuidv4()}`);
    var uniqueStyle = {
        mask: `url(#${uniqueIdentifier.current})`,
        WebkitMask: `url(#${uniqueIdentifier.current})`,
        MozMask: `url(#${uniqueIdentifier.current})`,
        msMask: `url(#${uniqueIdentifier.current})`,
        OMask: `url(#${uniqueIdentifier.current})`
    };
    
    const [dimensions, setDimensions] = useState({width: 0, height: 0});
    
    useEffect(() => {
        setDimensions(helpers.getTextDimensions(props.children));
    }, [props.children])

    return (
        <svg 
            width={`${dimensions.width+0.5}px`} 
            height={`${dimensions.height}px`} 
            className={`newButton ${props.dull ? "dull" : "notDull"} ${props.bg ? "fakeBackground" : ""}`} onClick={props.onClick}
        > 
            {!props.dull ?
            (
                <>
                    <defs>
                        <mask id={uniqueIdentifier.current} x="0" y="0" height="100%" width="100%">
                            <rect x="0" y="0" height="100%" width="100%" style={uniqueStyle} />
                            <text x="50%" y={!props.halfMast ? "50%" : "55%"} textAnchor="middle" className={props.className || ""} dominantBaseline="central" fill={"black"}>
                                {props.children}
                            </text>
                        </mask>
                    </defs>
                    <rect x="0" y="0" height="100%" width="100%" style={uniqueStyle}/>
                </>
            ) :
            (
                <text x="50%" y={!props.halfMast ? "50%" : "55%"} textAnchor="middle" className={props.className || ""} dominantBaseline="central" fill={"white"}>
                    {props.children}
                </text>
            )}
            
        </svg>

    )
}


export default TextMask;