import { Component } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { UserContext } from "../../contexts/UserContext";
import { appInfo, helpers, sendRequest } from "../../main";
import MainPage from "../../reusable/MainPage";
import deepEqual from "deep-equal";

class ViewUser extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = { 
            friend: "",
            friendInfo: {},
            exists: "unproven",
            removeStart: false,
        }
        this.retrieveUserInfo = this.retrieveUserInfo.bind(this);
        this.handleWebSocket = this.handleWebSocket.bind(this);
        this.removeFriendPrompt = this.removeFriendPrompt.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleInputChange(event) {
		const target = event.target;
		var value;
		if (target.type === "number") {
			value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
		} else if (target.type === "checkbox") {
			value = target.checked;
		} else {
			value = target.value;
		}

    	const name = target.name;
		
        if (name === "privacySettings") {
            value = parseInt(value);
        }

    	this.setState({
		    [name]: value
		});
	}
    handleWebSocket(name, data) {
        switch (name) {
            case "profileTrack":
                
                console.log(data)
                if (typeof data === "object") {
                    // console.log(data)
                    this.setState({
                        friendInfo: data
                    });
                }
                break;
        
            default:
                break;
        }
    }
    componentDidMount() {
        appInfo.connection.on("profileTrack", (data) => this.handleWebSocket("profileTrack", data));

        if (!this.props.active) {
            return;
        }

        var friendName = this.props.match.params.firstParam;
        if (helpers.validUsername(friendName)) {
            this.setState({
                friend: this.props.match.params.firstParam
            }, this.retrieveUserInfo);
            
        }
    }
    componentWillUnmount() {
        this.handleWebSocket = () => "";
        appInfo.connection.invoke("ProfileTrack", "exit.").catch((err) => {
            console.error(err);
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (!this.props.active) {
            return;
        }

        console.log("Hi")
        console.log(prevProps)
        if ((prevProps.match.params.firstParam !== this.props.match.params.firstParam) && helpers.validUsername(this.props.match.params.firstParam)) {     
            this.setState({
                friend: this.props.match.params.firstParam
            }, this.retrieveUserInfo);
        }

        if (!deepEqual(this.state, prevState)) {
            var areWeFriends = this.state.friendInfo.status === "friends" || false;
            var friendStatus = this.state.friendInfo.status || "unproven";

            this.props.handleSpecialBar(this.props.pageName,
                {
                    pageName: "profile",
                    buttonHubs: [
                        {
                            active: true,
                            buttons: [
                                {
                                    text: "Add Friend",
                                    function: () => this.retrieveUserInfo("makeFriend"),
                                    active: (!areWeFriends && friendStatus !== "requested"),
                                },
                            ]
                        }
                    ]
                }
            )
        }
    }
    retrieveUserInfo(action = "") {

        if (this.state.friend === this.context.user.name) {
            this.props.history.push("/profile");
            return;
        }

        sendRequest("api/specificFriend", {
            friendUsername: this.state.friend,
            action
        }).then((data) => {
            console.log(data)

            appInfo.connection.invoke("ProfileTrack", this.state.friend).catch((err) => {
                console.error(err);
            });

            if (!data.data) {
                this.retrieveUserInfo();
                return
            }

            this.setState({
                exists: "doesExist",
                friendInfo: data.data,
                removeStart: false
            }, () => {
                return true;
            })
        }).catch(() => {
            this.setState({
                exists: "doesNot"
            }, () => {
                return false;
            })
        })
    }
    removeFriendPrompt() {
        this.setState({
            removeStart: !this.state.removeStart
        })
    }
    render() { 
        var userProfile = this.state.friendInfo.friend;
        var areWeFriends = this.state.friendInfo.status === "friends" || false;
        var friendStatus = this.state.friendInfo.status || "unproven";

        if (this.state.exists === "unproven") {
            return <MainPage active={this.props.active} title={"loading"} />
        }
        

        var friendPage = ( 
            <MainPage active={this.props.active} title={areWeFriends ? "friends" : "users"} page={"profile"}>
                {(this.state.exists === "doesExist" && userProfile) ?
                    <div>
                        <div>
                            <div className={"username"}>@{userProfile.name}</div>
                            {!!userProfile.bio && <div className={"bio"}>"{userProfile.bio}"</div>}
                            <div className={"timestamp"}>{!!userProfile.timestamp ? `Joined us ${DateTime.fromISO(userProfile.timestamp).toRelative()}` : <span>A member of <span className={"logo"}>regimensocial</span></span>}</div>
                            <img
                                className={"standard profilePic"} 
                                src={helpers.getProfilePicture(userProfile.picture, userProfile.pictureCount)}
                                alt={"profile picture of "+userProfile.name}
                            />
                        </div>
                        
                        {friendStatus === "friends" &&
                            <p>You and <b>@{userProfile.name}</b> have been friends since {DateTime.fromISO(this.state.friendInfo.timestamp).toRelative()}</p>
                        }

                        {friendStatus === "requested" &&
                            <p>You and <b>@{userProfile.name}</b> aren't friends yet, but you have requested to be their friend. They should hopefully add you back soon.</p>
                        }

                        {friendStatus === "notRequested" &&
                            <p>You and <b>@{userProfile.name}</b> aren't friends yet, you can add them by pressing the buttom at the top.</p>
                        }

                        {friendStatus === "fan" &&
                            <p>You and <b>@{userProfile.name}</b> aren't friends yet, but they have requested to be your friend. You can accept their request by pressing the button at the top.</p>
                        }
                        
                        <div className={"groupsAndFriends"}>
                            <div className={"groupMenu"}>
                                {!!(userProfile.friends && userProfile.friends.length) &&
                                    <div>
                                        <b>@{this.state.friend}</b> has {userProfile.friends.length} friend{userProfile.friends.length > 1 && "s"} 
                                        <ul className={"noListPadding"}>
                                            {userProfile.friends.map((f) => {
                                                var me = f === this.context.user.name;

                                                return <li key={f}><Link to={!me ? "/u/" + f : "/profile"} {...this.props.enableInput}>{f}</Link></li>;
                                            })}
                                        </ul>
                                    </div>
                                }
                            </div>
                            <div className={"groupMenu"}>
                                {!!(userProfile.groups && userProfile.groups.length) &&
                                    <div>
                                        They are also in {userProfile.groups.length} group{userProfile.groups.length > 1 && "s"} 
                                        <ul className={"noListPadding"}>
                                            {userProfile.groups.map((f) => {
                                                return <li key={f}><Link to={"/g/" + f} {...this.props.enableInput}>{f}</Link></li>;
                                            })}
                                        </ul>
                                    </div>
                                }
                            </div>

                            {(friendStatus === "friends" || friendStatus === "requested") &&
                                <div className={"removeFriend"}>
                                    <span className={"fakeLink"} onClick={this.removeFriendPrompt}>Remove Friend.</span>
                                    <div 
                                        className={`bigger fakeLink ${!this.state.removeStart ? "noOpacity" : ""}`}
                                        onClick={() => this.retrieveUserInfo(this.state.removeStart ? "endFriendship" : "")}
                                    >
                                        I'm sure I want to remove this friend
                                    </div>
                                </div>
                            }
                        </div>
                        
                        
                    </div>
                : 
                    <div>
                        <b>@{this.state.friend}</b> doesn't appear to exist on regimensocial.
                    </div>
                }
                
            </MainPage>
        );

        return (
            <div>
                
                {friendPage}
            
            </div>
            
        )
    }
}
 
export default ViewUser;