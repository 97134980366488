const Miniselect = (props) => {
    return (
        <div className={`miniSelect ${props.className || ""}`}>
            {[...Array(props.amount)].map((x, i) => {
                var clickEvent = i+1 === props.active ? () => props.setStage(i+2) : () => props.setStage(i+1);
                if (i+1 === props.active && (i + 2 > props.amount)) {
                    clickEvent = null;
                } 
                return (
                    <div style={{width: (props.width || ""), height: (props.width || "")}} className={`item ${i+1 <= props.active ? "active" : "" } ${i+1 === props.active ? "activeLast" : "" } ${i === props.active ? "activeNext" : "" }`} key={i} onClick={clickEvent}>
                        <div className={"circle"}>
                            {(!!props.hint && i === 1) && <div className={"text"}>tap</div>}
                        </div>
                    </div>
                )
            })}
            
            {/* <div className={"arrow"} style={{width: (props.width || ""), height: (props.width || "")}}>
                <div></div>
            </div> */}
        </div>
    )
}

export default Miniselect;