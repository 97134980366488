const Privacy = () => (
    <div>
        <b>Privacy Policy</b>
        <div>
            i forgor 💀
        </div>
                        
    </div>
)

export default Privacy;