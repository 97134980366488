const Terms = () => (
    <div>
        <b>Terms and Conditions</b>
        <div>
            <span className={"logo"}>regimensocial</span> was entirely created by me, Jamie Adams, and it is managed solely by me. You won't have to worry about any third parties seeing any private data. You will be notified of any major changes to administration. 
        </div>
                        
    </div>
)

export default Terms;