// im naming it coolswitch because it's cool and I'm 16 year's old and I am the sole developer of this fucking project.

import TextMask from "./TextMask";

const Coolswitch = (props) => {
    return (
        <div className={`switch`} onClick={props.switchMode || null}>
        
            {props.items.map((text, i) => {
                return (
                    <div key={i} className={`item ${props.active === i ? "active" : ""}`} >
                        <TextMask halfMast={true} dull={props.active !== i} bg={true}>
                            {text}
                        </TextMask>
                    </div>
                );
            })}
        </div>
    )
}

export default Coolswitch;