import React, { useContext } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { UserContext } from "../contexts/UserContext";

// import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextMask from './TextMask';

var links = {
    loggedIn: [
        {
            info: "Home",
            link: "/"
        },
        {
            info: "Welcome",
            link: "/welcome"
        },
        {
            info: "You",
            link: "/profile"
        }
    ],
    notLoggedIn: [
        {
            info: "regimensocial",
            className: "logo",
            link: "/"
        },
        {
            info: "Join",
            link: "/enter"
        }
    ]
};

const NavBar = () => {
    const contextValue = useContext(UserContext);

    
    // eslint-disable-next-line
    var location = useLocation();
    let history = useHistory();

    function handleClick(link) {
        if (("/" + window.location.pathname.split("/")[1] === (link))) {
            return;
        }
        history.push(link);
    }
    

    return (
        <div>
			<div className={"footer desktop"}>
                {links[contextValue.loggedIn ? "loggedIn" : "notLoggedIn"].length > 1 && links[contextValue.loggedIn ? "loggedIn" : "notLoggedIn"].map((r, i) => {
                    return (
                        <div className={"item"} key={i} onClick={() => handleClick(r.link)} >
                            <TextMask className={r.className} radius={"0"} dull={!("/" + window.location.pathname.split("/")[1] === (r.link))} halfMast={true}>
                                {r.info}
                            </TextMask>
                        </div>
                    )
                })}
			</div>
            <div className={"footer mobile"}> {/* I have no clue why but two elements must exist for Safari to not fuck this up */}
                {links[contextValue.loggedIn ? "loggedIn" : "notLoggedIn"].length > 1 && links[contextValue.loggedIn ? "loggedIn" : "notLoggedIn"].map((r, i) => {
                    return (
                        <div className={`item ${("/" + window.location.pathname.split("/")[1] === (r.link)) ? "active" : ""}`} key={i} onClick={() => handleClick(r.link)} >
                            <TextMask radius={"0"} dull={!("/" + window.location.pathname.split("/")[1] === (r.link))} halfMast={true}>
                                {r.info}
                            </TextMask>
                        </div>
                    )
                })}
			</div>
            <div className={`socialBar ${window.location.pathname.split("/")[1] === ("social") ? "inactive" : "active"}`} onClick={() => handleClick("/social")}> 
                <div className="item">
                    {!!contextValue.friends.find(x => x.friend.online) ? `${contextValue.friends.filter(x => x.friend.online).length} online currently` : "Friends"}
                    <ul className="noBold">
                        {contextValue.friends.filter(x => x.friend.online).map((user, i) => {
                            if (user.friend.online) {
                                return (<li key={i}>{user.friend.name}</li>)
                            }
                            return null;
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default NavBar;