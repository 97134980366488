import { DateTime } from "luxon";
import { Component, createRef } from "react";
import { UserContext } from "../../contexts/UserContext";
import { helpers, sendRequest } from "../../main";
import MainPage from "../../reusable/MainPage";
import { Link } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';
import SlickContainer from "../../reusable/SlickContainer";
import deepEqual from "deep-equal";
import TextMask from "../../reusable/TextMask";

class Profile extends Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = { 
            editing: false,
            bio: "",
            newImage: "",
            privacySettings: 0
        }
        this.switchMode = this.switchMode.bind(this);
        this.initData = this.initData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.sendData = this.sendData.bind(this);
        this.fileUploader = createRef();
    }
    sendData() {
        var self = this;
        sendRequest("api/whoami", {
            action: "update",
			picture: this.state.newImage.split(",")[1] || "",
			bio: this.context.bio !== this.state.bio ? this.state.bio : "",
            privacySettings: this.state.privacySettings
			// privacySettings: this.state.privacySettings != this.context.privacySettings ? this.state.privacySettings : 0
        }).then((data) => {
            if (!data.data) {
                return "No data";
            }
            self.context.updateState("user", data.data);
            self.setState({
                editing: !self.state.editing
            })
        })
        .catch(() => {
        });
    }
    handleInputChange(event) {
		const target = event.target;
		var value;
		if (target.type === "number") {
			value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
		} else if (target.type === "checkbox") {
			value = target.checked;
		} else {
			value = target.value;
		}

    	const name = target.name;
		
        if (name === "privacySettings") {
            value = parseInt(value);
        }

    	this.setState({
		    [name]: value
		});
	}
    handleFileChange(e) {
        var self = this;
        helpers.readFileDataAsBase64(e, false).then((file) => {
            self.setState({
                newImage: file
            })
        }).catch((err) => {
            console.log(err)
        })
    }
    initData(callBack) {
        var userProfile = this.context.user;
        this.setState({
            bio: userProfile.bio,
            privacySettings: userProfile.privacySettings
        }, callBack)
    }
    switchMode() {
        this.initData(() => {
            this.setState({
                editing: !this.state.editing
            })
        });
        
    }
    componentDidUpdate(_prevProps, prevState) {
        if (!deepEqual(this.state, prevState)) {

            var userProfile = this.context.user;
            this.props.handleSpecialBar(this.props.pageName,
                {
                    pageName: "profile",
                    buttonHubs: [
                        {
                            active: true,
                            buttons: [
                                {
                                    text: !this.state.editing ? "Edit" : "Cancel",
                                    function: this.switchMode,
                                    active: true,
                                },
                                {
                                    text: "Save",
                                    function: this.sendData,
                                    active: this.state.editing && (this.state.bio !== userProfile.bio || this.state.newImage || userProfile.privacySettings !== this.state.privacySettings),
                                },
                            ]
                        }
                    ]
                }
            )
        }
    }
    componentDidMount() {
        this.initData();
    }
    render() { 
        var userProfile = this.context.user;
        
        return ( 
            <MainPage active={this.props.active} title={!this.state.editing ? "profile" : "editing profile"} page={"profile"}>

                <SlickContainer className={"sCmain"} items={[
                    
                    <div>
                        <div>
                            <div className={"username"}>@{userProfile.name}</div>
                            <div className={"bio"}>"{userProfile.bio}"</div>
                            <div className={"timestamp"}>Joined us {DateTime.fromISO(userProfile.timestamp).toRelative()}</div>
                            <img
                                className={"standard profilePic"} 
                                src={helpers.getProfilePicture(userProfile.picture, userProfile.pictureCount)}
                                alt={"profile picture of "+userProfile.name}
                            />
                        </div>
                        <div className={"groupMenu"}>
                            You're in {userProfile.groups.length} group{userProfile.groups.length > 1 && "s"} 
                            <ul className={"noListPadding"}>
                                {userProfile.groups.map((e) => {
                                    return <li key={e}><Link to={"/g/" + e} {...this.props.enableInput}>{e}</Link></li>;
                                })}
                            </ul>
                        </div>
                    </div>,
                    <div>
                        <div>
                            {/* <div className={"username"}>@{userProfile.name}</div> */}
                            <div className={"bio editing"}>
                                <TextareaAutosize 
                                    className={"regularText"}
                                    name={"bio"}
                                    value={this.state.bio}
                                    onChange={this.handleInputChange}
                                    disabled={!this.state.editing}
                                    {...this.props.enableInput}
                                />
                            </div>
                            {/* <div className={"timestamp"}>Joined us {DateTime.fromISO(userProfile.timestamp).toRelative()}</div> */}
                            {/* invisible file  */}
                            <input 
                                type="file"
                                ref={this.fileUploader} 
                                className={"hidden"}
                                name={"newImage"}
                                accept="image/png"
                                disabled={!this.state.editing}
                                onChange={this.handleFileChange}
                                {...this.props.enableInput}
                            />
                            <div className={"profilePicParent"}>
                                <img
                                    className={`standard profilePicEditing ${!this.state.newImage ? "editing" : ""}`} 
                                    src={this.state.newImage || helpers.getProfilePicture(userProfile.picture, userProfile.pictureCount)}
                                    alt={"profile picture of "+userProfile.name}
                                    onClick={() => this.fileUploader.current.click()}
                                />
                                <div className={`specialText ${this.state.newImage ? "partHidden" : ""}`} onClick={() => this.fileUploader.current.click()}>
                                    <TextMask dull={this.state.newImage} bg={true}>
                                        {!this.state.newImage ? "Tap to upload" : "Tap to change"}
                                    </TextMask>
                                </div>

                            </div>

                        </div>
                        <div className={"groupMenu"}>
                            Privacy Settings.
                            <ul className={"noListBullet infoList"}>
                                <li>
                                    <input disabled={!this.state.editing} type="radio" id={"ps1"} readOnly={true} value={1} onClick={this.handleInputChange} checked={this.state.privacySettings === 1} name="privacySettings" {...this.props.enableInput}/> 
                                    <label htmlFor={"ps1"}>Anyone can see my information.</label>
                                </li>
                                <li>
                                    <input disabled={!this.state.editing} type="radio" id={"ps2"} readOnly={true} value={2} onClick={this.handleInputChange} checked={this.state.privacySettings === 2} name="privacySettings" {...this.props.enableInput}/> 
                                    <label htmlFor={"ps2"}>My friends can see my information.</label>
                                </li>
                                <li>
                                    <input disabled={!this.state.editing} type="radio" id={"ps3"} readOnly={true} value={3} onClick={this.handleInputChange} checked={this.state.privacySettings === 3} name="privacySettings" {...this.props.enableInput}/> 
                                    <label htmlFor={"ps3"}>Only I can see my information.</label>
                                </li>
                            </ul>
                            
                        </div>
                        <div className={"groupMenu"}>
                            <div className={"fakeLink"} onClick={() => sendRequest("api/logout")}>
                                Log Out
                            </div>
                        </div>

                    </div>,
                ]} 
                    active={!this.state.editing ? 1 : 2}
                    delay={"700ms"}
                    noMargin={true}
                    padding={"0"}
                />
            </MainPage>
        );
    }
}
 
export default Profile;