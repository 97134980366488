import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import UserContextProvider from './contexts/UserContext';

window.isTabActive = true;
window.onfocus = function () { 
    window.isTabActive = true;
}; 

window.onblur = function () { 
    window.isTabActive = false;
}; 

const element = document.querySelector('body');

element.addEventListener('touchstart', (e) => {

    // // is not near edge of view, exit
    // if (e.pageX > 10 && e.pageX < window.innerWidth - 10) return;

    // // prevent swipe to navigate gesture
    // e.preventDefault();
    // alert("hi")
});

var secret = "";
var secretInterval = null;
var keyListen = (event) => {

    window.clearInterval(secretInterval);

    secret = secret + event.key;

    secretInterval = window.setTimeout(() => {
        secret = ""
    }, 1000)

    if (secret === "developer") {
        if (!document.body.classList.contains("dev")) {
            document.body.classList.add("dev");
        } else {
            document.body.classList.remove("dev");
        }
    }
    
}


document.addEventListener("keyup", keyListen);

console.log(
    "%cregimensocial, by jamie adams ❤️",
    "color:black;font-family:Segoe UI,'Open Sans',system-ui;font-size:2rem;font-variant:small-caps;"
);

ReactDOM.render(
    <React.StrictMode>
        
		<UserContextProvider>
            <App />
        </UserContextProvider>
        
    </React.StrictMode>,
    document.getElementById('root')
);

